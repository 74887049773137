import { default as indexUbl3bP9JmBMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/blog/index.vue?macro=true";
import { default as indexef92eAkqSLMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/companies/index.vue?macro=true";
import { default as indexBblLjV6SnQMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/index.vue?macro=true";
import { default as indexGIusoYdxPLMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/il/index.vue?macro=true";
import { default as indexcHhuHJBLVTMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/il/springfield/index.vue?macro=true";
import { default as index2mMqLjSYZaMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/il/springfield/tree-service/directory/index.vue?macro=true";
import { default as indexQiyp8Zpz8NMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/il/springfield/tree-service/index.vue?macro=true";
import { default as indexcOmwK5vLixMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/index.vue?macro=true";
import { default as indexi0BvtulbnEMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/ks/index.vue?macro=true";
import { default as indexd5Go51a1avMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/ks/lawrence/index.vue?macro=true";
import { default as indexYDUeHPZNcsMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/ks/lawrence/tree-service/directory/index.vue?macro=true";
import { default as indexBFG81O8lmdMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/ks/lawrence/tree-service/index.vue?macro=true";
import { default as indexc2dgmfl6xwMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/abilene/cpa/directory/index.vue?macro=true";
import { default as indexOKU24u9sVzMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/abilene/cpa/index.vue?macro=true";
import { default as indexQMQLnevDHHMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/abilene/index.vue?macro=true";
import { default as indexujN18kKTs5Meta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/arlington/cpa/directory/index.vue?macro=true";
import { default as index4hWslBJhYOMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/arlington/cpa/index.vue?macro=true";
import { default as indexOOjZGBWG7qMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/arlington/index.vue?macro=true";
import { default as indexlixPnwFtCWMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/austin/cpa/directory/index.vue?macro=true";
import { default as indexkE54qJRkgJMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/austin/cpa/index.vue?macro=true";
import { default as indexSCzuLffMgXMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/austin/index.vue?macro=true";
import { default as indexGHPWY03SC6Meta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/cedar-park/cpa/directory/index.vue?macro=true";
import { default as indexpdgdLubZcuMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/cedar-park/cpa/index.vue?macro=true";
import { default as indexDZ6UBVcn25Meta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/cedar-park/index.vue?macro=true";
import { default as indexO7syF2MjxzMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/corpus-christi/cpa/directory/index.vue?macro=true";
import { default as index2eTGwBybZyMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/corpus-christi/cpa/index.vue?macro=true";
import { default as index4LrRbdRnpvMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/corpus-christi/index.vue?macro=true";
import { default as index2Ub9ZW80xSMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/fort-worth/cpa/directory/index.vue?macro=true";
import { default as indexgeDg7nuy3mMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/fort-worth/cpa/index.vue?macro=true";
import { default as indexAISZS3kDzvMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/fort-worth/index.vue?macro=true";
import { default as indexDfqWftdLkDMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/georgetown/cpa/directory/index.vue?macro=true";
import { default as index3ry9v9k9ZKMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/georgetown/cpa/index.vue?macro=true";
import { default as indexYbaao5wftRMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/georgetown/index.vue?macro=true";
import { default as indexoo9cHtJzJCMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/index.vue?macro=true";
import { default as indexJh64EHLru1Meta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/lubbock/cpa/directory/index.vue?macro=true";
import { default as index8kIv04YICeMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/lubbock/cpa/index.vue?macro=true";
import { default as indexebVgmyO9XdMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/lubbock/index.vue?macro=true";
import { default as indexyC4wWzveP4Meta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/port-arthur/cpa/directory/index.vue?macro=true";
import { default as indexwhhNptzs0TMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/port-arthur/cpa/index.vue?macro=true";
import { default as indexdbRcuqiw09Meta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/port-arthur/index.vue?macro=true";
import { default as indextHGl2NfuGEMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/round-rock/cpa/directory/index.vue?macro=true";
import { default as indexBrJemJXyshMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/round-rock/cpa/index.vue?macro=true";
import { default as index3SoH1G4MfXMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/round-rock/index.vue?macro=true";
import { default as indexLhAPuzZzd9Meta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/san-marcos/cpa/directory/index.vue?macro=true";
import { default as indexfdXfCDoMjKMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/san-marcos/cpa/index.vue?macro=true";
import { default as indexP9xHGSCxnzMeta } from "/home/kev/git/rankrent/rankrent-fe/pages/us/tx/san-marcos/index.vue?macro=true";
export default [
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "companies",
    path: "/companies",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "us-il",
    path: "/us/il",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/il/index.vue").then(m => m.default || m)
  },
  {
    name: "us-il-springfield",
    path: "/us/il/springfield",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/il/springfield/index.vue").then(m => m.default || m)
  },
  {
    name: "us-il-springfield-tree-service-directory",
    path: "/us/il/springfield/tree-service/directory",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/il/springfield/tree-service/directory/index.vue").then(m => m.default || m)
  },
  {
    name: "us-il-springfield-tree-service",
    path: "/us/il/springfield/tree-service",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/il/springfield/tree-service/index.vue").then(m => m.default || m)
  },
  {
    name: "us",
    path: "/us",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/index.vue").then(m => m.default || m)
  },
  {
    name: "us-ks",
    path: "/us/ks",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/ks/index.vue").then(m => m.default || m)
  },
  {
    name: "us-ks-lawrence",
    path: "/us/ks/lawrence",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/ks/lawrence/index.vue").then(m => m.default || m)
  },
  {
    name: "us-ks-lawrence-tree-service-directory",
    path: "/us/ks/lawrence/tree-service/directory",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/ks/lawrence/tree-service/directory/index.vue").then(m => m.default || m)
  },
  {
    name: "us-ks-lawrence-tree-service",
    path: "/us/ks/lawrence/tree-service",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/ks/lawrence/tree-service/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-abilene-cpa-directory",
    path: "/us/tx/abilene/cpa/directory",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/abilene/cpa/directory/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-abilene-cpa",
    path: "/us/tx/abilene/cpa",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/abilene/cpa/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-abilene",
    path: "/us/tx/abilene",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/abilene/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-arlington-cpa-directory",
    path: "/us/tx/arlington/cpa/directory",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/arlington/cpa/directory/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-arlington-cpa",
    path: "/us/tx/arlington/cpa",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/arlington/cpa/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-arlington",
    path: "/us/tx/arlington",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/arlington/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-austin-cpa-directory",
    path: "/us/tx/austin/cpa/directory",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/austin/cpa/directory/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-austin-cpa",
    path: "/us/tx/austin/cpa",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/austin/cpa/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-austin",
    path: "/us/tx/austin",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/austin/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-cedar-park-cpa-directory",
    path: "/us/tx/cedar-park/cpa/directory",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/cedar-park/cpa/directory/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-cedar-park-cpa",
    path: "/us/tx/cedar-park/cpa",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/cedar-park/cpa/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-cedar-park",
    path: "/us/tx/cedar-park",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/cedar-park/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-corpus-christi-cpa-directory",
    path: "/us/tx/corpus-christi/cpa/directory",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/corpus-christi/cpa/directory/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-corpus-christi-cpa",
    path: "/us/tx/corpus-christi/cpa",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/corpus-christi/cpa/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-corpus-christi",
    path: "/us/tx/corpus-christi",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/corpus-christi/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-fort-worth-cpa-directory",
    path: "/us/tx/fort-worth/cpa/directory",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/fort-worth/cpa/directory/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-fort-worth-cpa",
    path: "/us/tx/fort-worth/cpa",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/fort-worth/cpa/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-fort-worth",
    path: "/us/tx/fort-worth",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/fort-worth/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-georgetown-cpa-directory",
    path: "/us/tx/georgetown/cpa/directory",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/georgetown/cpa/directory/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-georgetown-cpa",
    path: "/us/tx/georgetown/cpa",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/georgetown/cpa/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-georgetown",
    path: "/us/tx/georgetown",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/georgetown/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx",
    path: "/us/tx",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-lubbock-cpa-directory",
    path: "/us/tx/lubbock/cpa/directory",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/lubbock/cpa/directory/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-lubbock-cpa",
    path: "/us/tx/lubbock/cpa",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/lubbock/cpa/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-lubbock",
    path: "/us/tx/lubbock",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/lubbock/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-port-arthur-cpa-directory",
    path: "/us/tx/port-arthur/cpa/directory",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/port-arthur/cpa/directory/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-port-arthur-cpa",
    path: "/us/tx/port-arthur/cpa",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/port-arthur/cpa/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-port-arthur",
    path: "/us/tx/port-arthur",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/port-arthur/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-round-rock-cpa-directory",
    path: "/us/tx/round-rock/cpa/directory",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/round-rock/cpa/directory/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-round-rock-cpa",
    path: "/us/tx/round-rock/cpa",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/round-rock/cpa/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-round-rock",
    path: "/us/tx/round-rock",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/round-rock/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-san-marcos-cpa-directory",
    path: "/us/tx/san-marcos/cpa/directory",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/san-marcos/cpa/directory/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-san-marcos-cpa",
    path: "/us/tx/san-marcos/cpa",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/san-marcos/cpa/index.vue").then(m => m.default || m)
  },
  {
    name: "us-tx-san-marcos",
    path: "/us/tx/san-marcos",
    component: () => import("/home/kev/git/rankrent/rankrent-fe/pages/us/tx/san-marcos/index.vue").then(m => m.default || m)
  }
]