import revive_payload_client_Frd8gASYWa from "/home/kev/git/rankrent/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/home/kev/git/rankrent/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/home/kev/git/rankrent/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_l19uMh4P89 from "/home/kev/git/rankrent/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_T5aD6w6QZh from "/home/kev/git/rankrent/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JA9PnUIOcc from "/home/kev/git/rankrent/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/home/kev/git/rankrent/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kCUITUWXUN from "/home/kev/git/rankrent/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/kev/git/rankrent/rankrent-fe/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/home/kev/git/rankrent/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_GTyiibPwXi from "/home/kev/git/rankrent/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_WCZfpyw4WZ from "/home/kev/git/rankrent/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import siteConfig_ZWq9TlLEe2 from "/home/kev/git/rankrent/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_t3yL13D7A3 from "/home/kev/git/rankrent/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  _0_siteConfig_l19uMh4P89,
  payload_client_T5aD6w6QZh,
  navigation_repaint_client_JA9PnUIOcc,
  check_outdated_build_client_yxgjJ9XxnV,
  chunk_reload_client_kCUITUWXUN,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  titles_GTyiibPwXi,
  defaults_WCZfpyw4WZ,
  siteConfig_ZWq9TlLEe2,
  inferSeoMetaPlugin_t3yL13D7A3
]